<script lang="ts">
export default {
  name: 'DefaultLayout',
};
</script>
<script setup lang="ts">
// Navigation for default theme
const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData('mainNavigation', () => {
  return loadNavigationElements({ depth: 2 });
});
provide('swNavigation-main-navigation', data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: 'footer-navigation',
});
const { data: footerData } = useAsyncData('mainFooterNavigation', () => {
  return loadFooterNavigationElements({ depth: 2 });
});
provide('swNavigation-footer-navigation', footerData);
</script>
<template>
  <div class="flex min-h-screen min-w-full flex-col">
    <LayoutHeader class="w-full" />
    <LayoutNotifications />
    <main class="max-w-superlarge mx-auto w-full grow">
      <slot />
    </main>
    <LayoutFooter class="max-w-superlarge mx-auto w-full" />
  </div>
</template>
